import React, { useState, useEffect, useMemo } from "react";

import { message } from "antd";

import { useHistory, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { gtmAddLayer } from "../../app/Utilities";

import {
  fetchWorkshops,
  addWorkshop,
  deleteWorkshop,
  updateWorkshop,
  fetchCatalogs,
  fetchRoles,
} from "shared";

import * as workshopAPI from "shared/src/services/workshopAPI";
import { getError, getWorkshops, setCurrentSection } from "../../stores";

import { ConfigNavbar } from "../../app/Components/ConfigNavbar/index";
import { WorkshopSetup } from "./WorkshopSetup";
import AddCoworkersNEW from "../AddCoworkers/index";
import MyWorkshopsNEW from "../MyWorkshops/index";

import { Workshop } from "shared/src/data-models/Workshop";
import { CkMessage } from "../../CkUI";

interface IProps {
  initialState: number;
  logout: any;
  updateRoles: Function;
  redirectUserToHome: Function;
  fromWS: boolean;
  isMec: boolean;
  setStopLoop?: Function;
  exitWSForm: boolean;
  setExitWSForm: Function;
}

const MemoizedAccountSetup: React.FC<IProps> = ({
  initialState,
  logout,
  updateRoles,
  redirectUserToHome,
  fromWS,
  isMec,
  setStopLoop,
  exitWSForm,
  setExitWSForm,
}) => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const indexId = queryParams.get("indexId");

  const workshops: Workshop[] = useSelector(getWorkshops);
  const [error, errorDescription] = useSelector(getError);

  const [isNewAccount, setIsNewAccount] = useState<boolean>(false);
  const [isNewWs, setIsNewWs] = useState<boolean>(false);
  const [workshopId, setWorkshopId] = useState<number>(-1);
  const [currentStep, setCurrentStep] = useState<number>(initialState);
  const [wsIndex, setWsIndex] = useState<number>(0);

  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);

  useEffect(() => {
    if (indexId) {
      const index = workshops.findIndex((workshop) => workshop.id == indexId);
      goEditWorkshop(index);
    }
  }, [indexId]);

  useEffect(() => {
    if (window && window.location.pathname.includes("configura-tu-taller")) {
      setIsNewAccount(true);
    }
  }, [window]);

  useEffect(() => {
    dispatch(setCurrentSection("Mis talleres"));
    const fetchData = async () => {
      dispatch(fetchWorkshops(true)); // true brings disabled ws. this should be the same for all calls or it could bring inconsistencies
    };

    const fetchAllCatalogs = async () => {
      dispatch(fetchCatalogs("ES-MX"));
      dispatch(fetchRoles("ES-MX"));
    };

    if (!fromWS) {
      Promise.all([fetchAllCatalogs(), fetchData()]);
    } else {
      if (redirectUserToHome()) {
        history.replace("/citas");
      } else {
        Promise.all([fetchAllCatalogs(), fetchData()]);
      }
    }
  }, []);

  useEffect(() => {
    if (error) {
      CkMessage({ type: "error", text: errorDescription });
    } else if (
      !error &&
      errorDescription !== "" &&
      errorDescription !== undefined &&
      errorDescription !== "submit"
    ) {
      CkMessage({ type: "success", text: errorDescription });
    }
  }, [error, errorDescription]);

  useEffect(() => {
    if (exitWSForm) {
      setCurrentStep(0);
      setExitWSForm(false);
    }
  }, [exitWSForm]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentStep]);

  // useEffect(() => {
  //   if (workshops.length > 0) {
  //     updateRoles();
  //   }
  // }, [workshops]);

  const next = () => {
    if (currentStep < steps.length - 1)
      setCurrentStep((_currentStep) => _currentStep + 1);
  };

  const prev = () => {
    if (currentStep > 0) setCurrentStep((_currentStep) => _currentStep - 1);
  };

  const goEditWorkshop = (index: number) => {
    setWorkshopDetails(index);
    setWsIndex(index);
  };

  const setWorkshopDetails = (id: number) => {
    setWorkshopId(id);
    setCurrentStep(1);
  };

  const setWorkshopToWorkers = (id: number) => {
    setWorkshopId(id);
    setCurrentStep(2);
  };

  const RemoveWorkshop = async (workshopId: string | number) => {
    const loadingMessageKey = CkMessage({
      type: "loading",
      text: "Eliminanto taller...",
    });
    await dispatch(deleteWorkshop(workshopId))
      .then(() => {
        CkMessage({
          type: "success",
          text: "Taller eliminado exitosamente.",
          messageToDestroy: loadingMessageKey,
        });
      })
      .catch(() => {
        CkMessage({
          type: "error",
          text: "Se produjo un error al eliminar el taller.",
          messageToDestroy: loadingMessageKey,
        });
      });
    dispatch(fetchWorkshops(true)); // true brings disabled ws. this should be the same for all calls or it could bring inconsistencies
    history.push("/mis-talleres");
  };

  const AddWorkshop = async (workshop: Workshop) => {
    dispatch(addWorkshop(workshop)).then((result: any) => {
      // GOOGLE TAG

      let data = result.payload;
      gtmAddLayer({
        workshop_category: data.typeCode,
        workshop_ID: data.id,
        event: "add_workshop",
      });
    });
  };

  const UpdateWorkshop = (workshop: Workshop) => {
    dispatch(updateWorkshop(workshop));
  };

  const UpdateWorkshopNotif = (workshop: Workshop) => {
    workshopAPI.update(workshop);
  };

  const newWsModal = () => {
    if (isNewWs) {
      setShowWelcomeModal(true);
    }
  };

  const steps = useMemo(
    () => [
      {
        title: "Mis talleres",
        content: (
          <MyWorkshopsNEW
            workshops={workshops}
            setWsIndex={setWsIndex}
            RemoveWorkshop={RemoveWorkshop}
            UpdateWorkshop={UpdateWorkshopNotif}
            AddWorkshop={AddWorkshop}
            nextSetting={() => {
              setCurrentStep(1);
            }}
            prevSetting={prev}
            setWorkshopDetails={setWorkshopDetails}
            setWorkshopToWorkers={setWorkshopToWorkers}
            isMec={isMec}
            isNewAccount={isNewAccount}
          />
        ),
      },
      {
        title: "Detalles del taller",
        content: (
          <div
            className="cf-extra-marging"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <WorkshopSetup
              workshop={workshops[wsIndex]}
              UpdateWorkshop={UpdateWorkshop}
              setIsNewWs={setIsNewWs}
              nextSetting={() => setCurrentStep(0)}
              prevSetting={() => setCurrentStep(0)}
              setStopLoop={setStopLoop}
            />
          </div>
        ),
      },
      {
        title: "Mecánicos",
        content: (
          <div className="cf-extra-marging">
            <AddCoworkersNEW
              workshops={workshops}
              wsIndex={wsIndex}
              nextSetting={next}
              newWsModal={newWsModal}
              prevSetting={() => setCurrentStep(0)}
              workshopId={
                workshops[wsIndex] !== undefined
                  ? workshops.length > 0
                    ? workshops[wsIndex].id
                    : ""
                  : ""
              }
            />
          </div>
        ),
      },
    ],
    [workshops, workshopId, wsIndex, isNewWs]
  );

  return (
    <>
      {!fromWS && <ConfigNavbar logout={logout} />}
      <div className={`${!fromWS ? "cf-marging-header" : ""}`}>
        {steps[currentStep].content}
      </div>
    </>
  );
};

export const AccountSetup = React.memo(MemoizedAccountSetup);
