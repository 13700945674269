import React, { FormEvent, useRef } from "react";
import {
  Input,
  Form,
  Row,
  Col,
  Switch,
  Avatar,
  DatePicker,
  Upload,
} from "antd";
import { User } from "shared/src/data-models/User";
import moment from "moment";
import { FormInstance } from "antd/lib/form";
import { BlobServiceClient } from "@azure/storage-blob";
import { UserOutlined } from "@ant-design/icons";
import Compressor from "compressorjs";
import { gtmAddLayer } from "../../app/Utilities";
import { CkMessage } from "../../CkUI";

interface IProps {
  user: User;

  HandleChange: Function;
  HandleToggle: Function;
  HandleDateChange: Function;
  HandlePhotoChange: Function;
  form: FormInstance;
  sasToken: string;

  canEdit: boolean;
  canEditRole: boolean;
  canEditEmail: boolean;

  style?: React.CSSProperties;
  isSetup?: boolean | undefined | null;
}

const account = process.env.REACT_APP_STORAGEACCOUNT as string;
const containerName = "up-images-container";
let blobService: any = null;
let containerClient: any = null;

export const Coworker: React.FC<IProps> = ({
  user,
  HandleChange,
  HandleToggle,
  HandleDateChange,
  HandlePhotoChange,
  form,
  sasToken,
  canEdit,
  canEditRole,
  style,
  canEditEmail,
  isSetup,
}) => {
  const loadingMessageKey = useRef<string>("");
  
  React.useEffect(() => {
    if (sasToken) {
      blobService = new BlobServiceClient(`${account}?${sasToken}`);
      containerClient = blobService.getContainerClient(containerName);
    }
  }, [sasToken]);

  const props = {
    //fileList,
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: (file: any) => {
      let imgType: Object = {
        "image/png": true,
        "image/jpg": true,
        "image/jpeg": true,
      };
      if (!imgType.hasOwnProperty(file.type)) {
        CkMessage({
          type: "error",
          text: `${file.name} no es un formato soportado.`,
        });
      }
      return imgType.hasOwnProperty(file.type);
    },
    customRequest: ({ onSuccess, onError, file }: any) => {
      uploadImage(onSuccess, onError, file);
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "uploading") {
        loadingMessageKey.current = CkMessage({
          type: "loading",
          text: `Subiendo imagen ${info.file.name}`,
          ...(loadingMessageKey.current ? {messageToDestroy: loadingMessageKey.current} : {})
        });
      } else if (status === "done") {
        CkMessage({
          type: "success",
          text: `${info.file.name} subida exitosamente.`,
          ...(loadingMessageKey.current ? {messageToDestroy: loadingMessageKey.current} : {})
        });
        if (loadingMessageKey.current) loadingMessageKey.current = "";
        if (isSetup) {
          gtmAddLayer({
            event: "click_upload_image_on_setup"
          });
        }
      } else if (status === "error") {
        CkMessage({
          type: "error",
          text: `${info.file.name} error al subir imagen`,
          ...(loadingMessageKey.current ? {messageToDestroy: loadingMessageKey.current} : {})
        });
        if (loadingMessageKey.current) loadingMessageKey.current = "";
      }
    },
  };

  const uploadImage = async (
    onSuccess: Function,
    onError: Function,
    file: any
  ) => {
    let date = new Date();
    let fileName = `${date.getTime()}_${file.name}`;
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    try {
      new Compressor(file, {
        quality: 0.6,
        convertTypes:
          "image/png, image/webp, image/gif, image/tiff, image/apng, image/ico, image/cur, image/ai, image/svg, image/raw, image/jfif",
        convertSize: 2000000,
        async success(result) {
          await blockBlobClient.upload(result, result.size);
          HandlePhotoChange(blockBlobClient.url.split("?")[0]);
          onSuccess();
        },
      });
    } catch (error) {
      onError();
    }
  };

  const validateLegalYear = (date: any) => {
    const dateOfBirth = new Date(date);
    const legalAge = 18;

    if (
      new Date(
        dateOfBirth.getFullYear() + legalAge,
        dateOfBirth.getMonth(),
        dateOfBirth.getDate()
      ) <= new Date()
    ) {
      HandleDateChange(date);
    } else {
      CkMessage({ type: "error", text: "Usuario es menor de edad."});
      return;
    }
  };

  return (
    <Form name="coworker" initialValues={user} form={form} style={{ ...style }}>
      <Row gutter={[8, 16]}>
        <Col md={24} sm={24} xs={24}>
          <Avatar
            size={64}
            src={
              user.photoUrl !== "" ? (
                `${user.photoUrl}?${sasToken}`
              ) : (
                <UserOutlined
                  style={{ fontSize: "30px", transform: "translate(0px, 3px)" }}
                />
              )
            }
            style={{ backgroundColor: "#5696e2" }}
          />
          <p
            style={{ display: "inline", marginLeft: "1rem", color: "#454545" }}
            hidden={!canEdit}
          >
            <Upload {...props}>
              <a>Seleccionar foto</a>
            </Upload>
          </p>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Ingresa el nombre del usuario",
              },
              {
                pattern: new RegExp(/^([^0-9]*)$/),
                message: "No se permiten números",
              },
            ]}
            name="name"
            extra="Nombre"
            style={{ margin: 0 }}
          >
            <Input
              //size="large"
              className="--custom"
              name="name"
              placeholder="Nombre"
              value={user.name}
              onBlur={(
                evt: FormEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => HandleChange(evt)}
              disabled={!canEdit}
            />
          </Form.Item>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Ingresa el apellido del usuario",
              },
              {
                pattern: new RegExp(/^([^0-9]*)$/),
                message: "No se permiten números",
              },
            ]}
            name="lastName"
            extra="Apellido"
            style={{ margin: 0 }}
          >
            <Input
              //size="large"
              className="--custom"
              name="lastName"
              placeholder="Apellido"
              value={user.lastName}
              onBlur={(
                evt: FormEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => HandleChange(evt)}
              disabled={!canEdit}
            />
          </Form.Item>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Ingresa la fecha de nacimiento del usuario",
              },
            ]}
            extra="Fecha de nacimiento"
            style={{ margin: 0 }}
            //name="dateOfBirth"
          >
            <DatePicker
              className="--custom"
              inputReadOnly
              //size="large"
              style={{ width: "100%" }}
              name="dateOfBirth"
              placeholder="Fecha de nacimiento"
              value={
                user.dateOfBirth
                  ? moment(user.dateOfBirth.split("T")[0])
                  : undefined
              }
              onChange={validateLegalYear}
              disabled={!canEdit}
              disabledDate={(d) => d.isBefore("1900-01-01")}
              popupStyle={{ zIndex: window.screen.width > 992 ? 1000 : 2600 }}
              showToday={false}
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Ingresa el teléfono celular del usuario",
              },
              {
                pattern: new RegExp(/^[0-9]*$/gi),
                message: "Sólo se permiten números",
              },
              {
                max: 10,
                min: 10,
                message: "El teléfono celular debe ser de 10 números",
              },
            ]}
            name="phone"
            extra="Teléfono celular"
            style={{ margin: 0 }}
          >
            <Input
              //size="large"
              className="--custom"
              name="phone"
              placeholder="Teléfono celular"
              value={user.phone}
              onBlur={(
                evt: FormEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => HandleChange(evt)}
              disabled={!canEdit}
            />
          </Form.Item>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Ingresa el correo electrónico del usuario",
              },
              {
                type: "email",
                message: "Debes ingresar un correo electrónico",
              },
            ]}
            name="email"
            extra="Correo electrónico"
            style={{ margin: 0 }}
          >
            <Input
              //size="large"
              className="--custom"
              name="email"
              placeholder="Correo electrónico"
              value={user.email}
              onBlur={(
                evt: FormEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => HandleChange(evt)}
              disabled={!canEdit || !canEditEmail}
              //readOnly={!canEditEmail}
            />
          </Form.Item>
        </Col>
        <Col md={24} sm={24} xs={24}>
          <Form.Item
            name="roleId"
            hidden={
              user.userRoles.findIndex((role) => role.roleCode === "OWN") !== -1
            }
            style={{ margin: 0 }}
          >
            <Switch
              className="--custom"
              checked={
                user.userRoles.length > 0
                  ? user.userRoles[0].roleCode == "ADM"
                  : false
              }
              onChange={(checked) => HandleToggle(checked)}
              disabled={!canEditRole}
            />
            <p
              style={{
                display: "inline",
                color: "#454545",
                marginLeft: 5,
              }}
            >
              Administrador - Puede cambiar detalles del taller y de los
              mecánicos
            </p>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
