const destructureWarrantyTableData = (
  tableData: any[],
  isServices: boolean
) => {
  const tempList = [];
  let isThereError = "";
  tableData.forEach((item) => {
    if (isThereError !== "") return;

    const isManualLaborChecked =
      item.manualLabor.enable &&
      (item.manualLabor.time !== "" || item.manualLabor.km !== "");
    const isPartsChecked =
      item.parts.enable && (item.parts.time !== "" || item.parts.km !== "");

    if (isServices) {
      if (isManualLaborChecked) {
        let timeRange = item.manualLabor.timeRange;

        if (item.manualLabor.time === "" || item.manualLabor.time === 0) {
          timeRange = "";
        } else if (timeRange !== "") {
          timeRange = timeRange === "month" ? "MES" : "DIA";
        }

        if (item.manualLabor.time !== "" && item.manualLabor.timeRange === "") {
          isThereError = "errTimeRange";
          return;
        }

        const tempManualLabor = {
          srvcTypeCode: item.serviceTypeCode,
          warrantyTypeCode: "WAR001", // Mano de obra
          warrantyTime: item.manualLabor.time ? item.manualLabor.time : 0,
          warrantyTimeApplies: item.manualLabor.time !== "",
          warrantyTimeUnit: timeRange,
          warrantyKilometers: item.manualLabor.km ? item.manualLabor.km : 0,
          warrantyKilometersApplies: item.manualLabor.km !== "",
        };
        tempList.push(tempManualLabor);
      }
      if (isPartsChecked) {
        let timeRange = item.parts.timeRange;

        if (item.parts.time === "" || item.parts.time === 0) {
          timeRange = "";
        } else if (timeRange !== "") {
          timeRange = timeRange === "month" ? "MES" : "DIA";
        }

        if (item.parts.time !== "" && item.parts.timeRange === "") {
          isThereError = "errTimeRange";
          return;
        }

        const tempParts = {
          srvcTypeCode: item.serviceTypeCode,
          warrantyTypeCode: "WAR002", // Refracciones
          warrantyTime: item.parts.time ? item.parts.time : 0,
          warrantyTimeApplies: item.parts.time !== "",
          warrantyTimeUnit: timeRange,
          warrantyKilometers: item.parts.km ? item.parts.km : 0,
          warrantyKilometersApplies: item.parts.km !== "",
        };
        tempList.push(tempParts);
      }
    } else {
      if (isManualLaborChecked) {
        let timeRange = item.manualLabor.timeRange;

        if (item.manualLabor.time === "" || item.manualLabor.time === 0) {
          timeRange = "";
        } else if (timeRange !== "") {
          timeRange = timeRange === "month" ? "MES" : "DIA";
        }

        if (item.manualLabor.time !== "" && item.manualLabor.timeRange === "") {
          isThereError = "errTimeRange";
          return;
        }

        const tempManualLabor = {
          servicePackageCode: item.servicePackageCode,
          warrantyTypeCode: "WAR001", // Mano de obra
          warrantyTime: item.manualLabor.time ? item.manualLabor.time : 0,
          warrantyTimeApplies: item.manualLabor.time !== "",
          warrantyTimeUnit: timeRange,
          warrantyKilometers: item.manualLabor.km ? item.manualLabor.km : 0,
          warrantyKilometersApplies: item.manualLabor.km !== "",
        };
        tempList.push(tempManualLabor);
      }
      if (isPartsChecked) {
        let timeRange = item.parts.timeRange;

        if (item.parts.time === "" || item.parts.time === 0) {
          timeRange = "";
        } else if (timeRange !== "") {
          timeRange = timeRange === "month" ? "MES" : "DIA";
        }

        if (item.parts.time !== "" && item.parts.timeRange === "") {
          isThereError = "errTimeRange";
          return;
        }

        const tempParts = {
          servicePackageCode: item.servicePackageCode,
          warrantyTypeCode: "WAR002", // Refracciones
          warrantyTime: item.parts.time ? item.parts.time : 0,
          warrantyTimeApplies: item.parts.time !== "",
          warrantyTimeUnit: timeRange,
          warrantyKilometers: item.parts.km ? item.parts.km : 0,
          warrantyKilometersApplies: item.parts.km !== "",
        };
        tempList.push(tempParts);
      }
    }
  });
  if (isThereError !== "") return isThereError;

  return tempList;
};

export const formatWarrantyData = (values: any) => {
  if (
    (!values.warranty.servicesInfo &&
      !values.warranty.packagesInfo &&
      !values.warranty.conditions &&
      !values.warranty.exclusions &&
      !values.warranty.toStart) ||
    !values.warranty.enable
  ) {
    return {
      workshopWarrantyFileUrl: "",
      warrantyClauses: [],
      warrantyServices: [],
      warrantyPackages: [],
    };
  }

  const clauses = [];
  let url = "";

  if (values.warranty.conditions.includes("https")) {
    url = values.warranty.conditions;
  } else if (values.warranty.conditions) {
    clauses.push({
      clauseCode: "CLA001",
      workshopWarrantyClauseText: values.warranty.conditions,
    });
  }

  if (values.warranty.exclusions) {
    clauses.push({
      clauseCode: "CLA002",
      workshopWarrantyClauseText: values.warranty.exclusions,
    });
  }
  if (values.warranty.toStart) {
    clauses.push({
      clauseCode: "CLA003",
      workshopWarrantyClauseText: values.warranty.toStart,
    });
  }

  return {
    workshopWarrantyFileUrl: url,
    warrantyClauses: clauses,
    warrantyServices: destructureWarrantyTableData(
      values.warranty.servicesInfo,
      true
    ),
    warrantyPackages: destructureWarrantyTableData(
      values.warranty.packagesInfo,
      false
    ),
  };
};
