import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form, Row, Button, Col, Layout, Affix, Spin } from "antd";
import { Workshop } from "shared/src/data-models/Workshop";
import { getSasToken } from "../../stores/selectors";
import { BlobServiceClient } from "@azure/storage-blob";
import { ExxonWorkshop } from "../../app/Components/Workshop/Workshop";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentSection } from "../../stores";
import {
  PackagesAPI,
  WorkshopAPI,
  ServicesAPI,
  WarrantyAPI,
} from "../../app/Services/axios";
import { CurrentUser } from "../../context/UserContext";
import { useWindowSize } from "../../app/Utilities/WindowSize";
import ConfirmationMessage from "../../app/Components/Modals/ConfirmationMessage";
import { getLoading } from "../../stores";
import { CkButton, CkMessage } from "../../CkUI";
import { formatWarrantyData } from "./warrantyFunctions";

interface IProps {
  workshop?: Workshop;
  UpdateWorkshop: Function;
  setIsNewWs: Function;
  nextSetting: Function;
  nextStepText?: string;
  prevSetting?: Function;
  setStopLoop?: Function;
  /**
   * Use this prop to prevent package and services configuration buttons
   * to be shown and prevent some validations
   * Currently use for new Add Workshop flow
   */
  hidePackagesAndServices?: boolean;
  topAffix?: boolean;
}

interface IVal {
  id: string;
  name: string;
  email: string;
  sucursalName: string;
}

interface IBlobImages {
  name: string;
}

export interface IPackage {
  servicePackageCode: string;
  servicePackage: {
    servicePackageCode: string;
    servicePackageDesc: string;
    isActive: boolean;
  };
  servicePackageMap: any[];
  servicePackageSetId: number;
  servicePackageType: {
    servicePackageTypeCode: string;
    servicePackageTypeDesc: string;
  };
  servicePackageTypeCode: string;
  price?: number;
  comment?: string;
}

interface WorkshopPackage {
  price: number;
  servicePackageSetId: number;
  servicePackageSet: IPackage;
  workshopAppointmentPackageMaps: any[];
  workshopId: number;
}

const account = process.env.REACT_APP_BLOB_ACCOUNT;
const containerName = process.env.REACT_APP_IMAGES_CONTAINER as string;
let blobService: any = null;
let containerClient: any = null;

export const WorkshopSetup: React.FC<IProps> = ({
  workshop,
  UpdateWorkshop,
  setIsNewWs,
  prevSetting,
  nextSetting,
  nextStepText,
  setStopLoop,
  hidePackagesAndServices = false,
  topAffix = true,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [displayModal, setDisplayModal] = useState<boolean>(false);

  const user = useContext(CurrentUser);
  const windowSize = useWindowSize();

  const [blobListDeleted, setBlobListDeleted] = useState<Array<IBlobImages>>(
    []
  );

  const [workshopForm, setWorkshopForm] = useState<any>({});
  const [packageList, setPackageList] = useState<IPackage[]>([]);
  const sasToken: string = useSelector(getSasToken);
  const loading = useSelector(getLoading);
  const [saving, setSaving] = useState<boolean>(false);

  const savePackages = (workshopId: string) => {
    const packages = packageList.map((pack) => {
      return {
        ServicePackagesetId: pack.servicePackageSetId,
        Price: pack.price,
        Comment: pack.comment,
      };
    });
    PackagesAPI.postWorkshopPackages(workshopId, packages);
  };

  const saveServices = (workshopId: string, payload: any[]) => {
    ServicesAPI.updateServices(workshopId, payload);
  };

  const saveWarranty = (workshopId: string, payload: any) => {
    WarrantyAPI.updateWarranty(workshopId, payload);
  };

  useEffect(() => {
    if (sasToken) {
      blobService = new BlobServiceClient(`${account}?${sasToken}`);
      containerClient = blobService.getContainerClient(containerName);
    }
  }, [sasToken]);

  const structureWarrantyData = (tableData: any, isService: boolean) => {
    if (!tableData) {
      return [];
    }

    const tempData = [];
    const tempIds = [];

    if (isService) {
      tableData.forEach((item: any) => {
        if (tempIds.includes(item.srvcTypeCode)) {
          return;
        }

        const filteredData = [
          tableData.filter(
            (child: any) =>
              child.srvcTypeCode === item.srvcTypeCode &&
              child.warrantyTypeDescription === "Mano de Obra"
          )[0],
          tableData.filter(
            (child: any) =>
              child.srvcTypeCode === item.srvcTypeCode &&
              child.warrantyTypeDescription === "Refracciones"
          )[0],
        ];

        const tempManualLabor = {
          enable: false,
          timeRange: "",
          time: "",
          km: "",
        };
        const tempParts = { enable: false, timeRange: "", time: "", km: "" };
        const firstChild = filteredData[0]; // Mano de obra
        const secondChild = filteredData[1]; // Refracciones

        if (firstChild) {
          let timeRange = "";
          if (firstChild.warrantyTimeApplies) {
            if (firstChild.warrantyTimeUnit === "MES") {
              timeRange = "month";
            } else if (firstChild.warrantyTimeUnit === "DIA") {
              timeRange = "day";
            }
          }

          tempManualLabor.enable =
            firstChild.warrantyKilometersApplies ||
            firstChild.warrantyTimeApplies;
          tempManualLabor.timeRange = timeRange;
          tempManualLabor.time =
            firstChild.warrantyTime === 0 ? "" : firstChild.warrantyTime;
          tempManualLabor.km =
            firstChild.warrantyKilometers === 0
              ? ""
              : firstChild.warrantyKilometers;
        }
        if (secondChild) {
          let timeRange = "";
          if (secondChild.warrantyTimeApplies) {
            if (secondChild.warrantyTimeUnit === "MES") {
              timeRange = "month";
            } else if (secondChild.warrantyTimeUnit === "DIA") {
              timeRange = "day";
            }
          }

          tempParts.enable =
            secondChild.warrantyKilometersApplies ||
            secondChild.warrantyTimeApplies;
          tempParts.timeRange = timeRange;
          tempParts.time =
            secondChild.warrantyTime === 0 ? "" : secondChild.warrantyTime;
          tempParts.km =
            secondChild.warrantyKilometers === 0
              ? ""
              : secondChild.warrantyKilometers;
        }
        tempData.push({
          name: item.srvcTypeDesc,
          enable: true,
          manualLabor: tempManualLabor,
          parts: tempParts,
          isService: isService,
          serviceTypeCode: item.srvcTypeCode,
        });
        tempIds.push(item.srvcTypeCode);
      });
    } else {
      tableData.forEach((item: any) => {
        if (tempIds.includes(item.servicePackageCode)) {
          return;
        }

        const filteredData = [
          tableData.filter(
            (child: any) =>
              child.servicePackageCode === item.servicePackageCode &&
              child.warrantyTypeDescription === "Mano de Obra"
          )[0],
          tableData.filter(
            (child: any) =>
              child.servicePackageCode === item.servicePackageCode &&
              child.warrantyTypeDescription === "Refracciones"
          )[0],
        ];
        const tempManualLabor = {
          enable: false,
          timeRange: "",
          time: "",
          km: "",
        };
        const tempParts = { enable: false, timeRange: "", time: "", km: "" };
        const firstChild = filteredData[0]; // Mano de obra
        const secondChild = filteredData[1]; // Refracciones

        if (firstChild) {
          let timeRange = "";
          if (firstChild.warrantyTimeApplies) {
            if (firstChild.warrantyTimeUnit === "MES") {
              timeRange = "month";
            } else if (firstChild.warrantyTimeUnit === "DIA") {
              timeRange = "day";
            }
          }

          tempManualLabor.enable =
            firstChild.warrantyKilometersApplies ||
            firstChild.warrantyTimeApplies;
          tempManualLabor.timeRange = timeRange;
          tempManualLabor.time =
            firstChild.warrantyTime === 0 ? "" : firstChild.warrantyTime;
          tempManualLabor.km =
            firstChild.warrantyKilometers === 0
              ? ""
              : firstChild.warrantyKilometers;
        }
        if (secondChild) {
          let timeRange = "";
          if (secondChild.warrantyTimeApplies) {
            if (secondChild.warrantyTimeUnit === "MES") {
              timeRange = "month";
            } else if (secondChild.warrantyTimeUnit === "DIA") {
              timeRange = "day";
            }
          }
          tempParts.enable =
            secondChild.warrantyKilometersApplies ||
            secondChild.warrantyTimeApplies;
          tempParts.timeRange = timeRange;
          tempParts.time =
            secondChild.warrantyTime === 0 ? "" : secondChild.warrantyTime;
          tempParts.km =
            secondChild.warrantyKilometers === 0
              ? ""
              : secondChild.warrantyKilometers;
        }
        tempData.push({
          name: item.servicePackageDesc,
          enable: true,
          manualLabor: tempManualLabor,
          parts: tempParts,
          isService: isService,
          servicePackageCode: item.servicePackageCode,
        });
        tempIds.push(item.servicePackageCode);
      });
    }

    return tempData;
  };

  const getWorkshopWarranty = async (workshop: any) => {
    const result = await WarrantyAPI.getWarranty(workshop.id);
    const warrantyData = result.data;

    let conditions = "";

    if (
      warrantyData?.workshopWarrantyFileUrl &&
      warrantyData?.workshopWarrantyFileUrl !== ""
    )
      conditions = warrantyData?.workshopWarrantyFileUrl;
    else if (warrantyData?.warrantyClauses?.length > 0)
      conditions =
        warrantyData?.warrantyClauses?.[0]?.workshopWarrantyClauseText;

    return {
      enable:
        warrantyData.warrantyClauses.length > 0 ||
        (!!warrantyData.workshopWarrantyFileUrl &&
          warrantyData.workshopWarrantyFileUrl !== "") ||
        warrantyData.warrantyServices?.length > 0 ||
        warrantyData.warrantyPackages?.length > 0,
      conditions: conditions,
      exclusions:
        warrantyData?.warrantyClauses[1]?.workshopWarrantyClauseText || "",
      toStart:
        warrantyData?.warrantyClauses[2]?.workshopWarrantyClauseText || "",
      servicesInfo: structureWarrantyData(warrantyData?.warrantyServices, true),
      packagesInfo: structureWarrantyData(
        warrantyData?.warrantyPackages,
        false
      ),
    };
  };

  useEffect(() => {
    const getServices = async () => {
      if (workshop && workshop.id && workshop.id !== "") {
        setWorkshopForm({
          ...workshop,
          carTypes: workshop.carTypes?.map((item) => item.code) || [],

          warranty: await getWorkshopWarranty(workshop),
        });
      }
    };
    workshop && getServices();
  }, [workshop]);

  useEffect(() => {
    if (
      hidePackagesAndServices === false &&
      workshop &&
      workshop.email.length == 0
    ) {
      CkMessage({
        type: "info",
        text: "¡Despues de configurar tu taller recuerda que debes completar la encuesta para que tu taller sea visible!",
      });
    }
  }, [workshop?.email.length]);

  useEffect(() => {
    if (workshop) {
      PackagesAPI.getPackagesDetail().then((response: any) => {
        const packages = response.data.filter(
          (pack: IPackage) => pack.servicePackage.isActive
        );

        PackagesAPI.getWorkshopPackages(workshop.id!).then((res: any) => {
          const full = packages.map((pack: IPackage) => {
            const match = res.data.find(
              (a: WorkshopPackage) =>
                a.servicePackageSetId === pack.servicePackageSetId
            );
            if (match) {
              return {
                ...pack,
                price: match.price,
                comment: match.comment ? match.comment : "",
              };
            } else {
              return { ...pack, price: 0, comment: "" };
            }
          });
          setPackageList(full);
        });
      });
    }
  }, [workshop]);

  useEffect(() => {
    hidePackagesAndServices === false &&
      workshop &&
      dispatch(setCurrentSection("Editar taller"));
    function HandleLeavePage(e: any) {
      if (sessionStorage.getItem("imagesWorkshop")) {
        var confirmationMessage =
          "¿Estás seguro que deseas recargar la página?" +
          "Si no guardas antes de salir tus cambios se perderán.";
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      }

      return null;
    }

    window.addEventListener("beforeunload", HandleLeavePage, false);

    return () => {
      window.removeEventListener("beforeunload", HandleLeavePage);
    };
  }, []);

  const HandlerSetForm = useCallback(
    (evt: any) => {
      let formValues = form.getFieldsValue();
      if (formValues.openSince === "") formValues.openSince = null;
      let updateWorkshop: any;
      setWorkshopForm((_workshopForm) => {
        updateWorkshop = { ..._workshopForm, ...formValues, ...evt };
        return updateWorkshop;
      });
      if (!updateWorkshop && evt.email) {
        form.setFields([{ name: "email", value: evt.email }]);
      }

      form.setFieldsValue(updateWorkshop);
    },
    [form]
  );

  const doUpdate = async (values: any, exitModal: boolean = true) => {
    const formatedWarrantyData = formatWarrantyData(values);

    if (
      formatedWarrantyData.warrantyServices === "errTimeRange" ||
      formatedWarrantyData.warrantyPackages === "errTimeRange"
    ) {
      CkMessage({
        type: "warning",
        text: "El rango de tiempo de las garantías no puede estar vacío",
      });
      return;
    }

    saveWarranty(workshop.id, formatedWarrantyData);

    if (setStopLoop) {
      setTimeout(() => {
        setStopLoop(true);
      }, 2000);
    }

    if (workshop === undefined || workshop.email.length === 0) {
      setIsNewWs(true);
    }
    let workingHours = [];

    if (
      values.UseSameTimeEveryDay &&
      values.everyDayHours.startingTime &&
      values.everyDayHours.endingTime
    ) {
      let openTime = values.everyDayHours.startingTime.toDate().toISOString();
      let closeTime = values.everyDayHours.endingTime.toDate().toISOString();
      openTime = openTime.substring(openTime.indexOf("T") + 1);
      closeTime = closeTime.substring(closeTime.indexOf("T") + 1);
      const dayCodes = [1, 2, 3, 4, 5, 6, 7];
      dayCodes.forEach((dayCode) => {
        workingHours.push({
          dayCode: dayCode,
          openTime: openTime,
          closeTime: closeTime,
          isOpen: true,
        });
      });
    } else if (
      values.UseSameTimeMondayToFriday &&
      values.mondayToFridayDayHours.startingTime &&
      values.mondayToFridayDayHours.endingTime
    ) {
      let openTime = values.mondayToFridayDayHours.startingTime
        .toDate()
        .toISOString();
      let closeTime = values.mondayToFridayDayHours.endingTime
        .toDate()
        .toISOString();
      openTime = openTime.substring(openTime.indexOf("T") + 1);
      closeTime = closeTime.substring(closeTime.indexOf("T") + 1);
      const dayCodes = [1, 2, 3, 4, 5];
      dayCodes.forEach((dayCode) => {
        workingHours.push({
          dayCode: dayCode,
          openTime: openTime,
          closeTime: closeTime,
          isOpen: true,
        });
      });

      //For Saturday and Sunday

      workingHours.push({
        dayCode: values.workingHours[5].dayCode,
        openTime: values.workingHours[5].openTime,
        closeTime: values.workingHours[5].closeTime,
        isOpen: values.workingHours[5].isOpen,
      });

      workingHours.push({
        dayCode: values.workingHours[6].dayCode,
        openTime: values.workingHours[6].openTime,
        closeTime: values.workingHours[6].closeTime,
        isOpen: values.workingHours[6].isOpen,
      });
    } else {
      values.workingHours?.forEach((workinghr: any) => {
        if (workinghr.isOpen) {
          if (workinghr.closeTime === "" || workinghr.openTime === "") {
            throw new Error("WHRERROR");
          }
        }
      });
      workingHours = values.workingHours;
    }
    let nameValue = values.name;
    let nameValueUpperCase = nameValue.toUpperCase();

    let newCarTypes =
      values.carTypes?.map((item: any) => ({
        code: item.toUpperCase(),
        description: item,
      })) || [];

    if (workshop === undefined) {
      return;
    }

    const photosOrdered = workshopForm.workshopPhotos.map(
      (photo: any, index: number) => ({ ...photo, order: index })
    );

    let toUpdate = {
      id: workshop.id,
      name: nameValueUpperCase,
      typeCodes: workshop.typeCodes,
      email: values.email,
      mySAPId: values.mySAPId,
      openSince: values.openSince ?? "-1",
      workshopPhoneNumber: values.workshopPhoneNumber?.split("-").join(""),
      customerPhoneNumber: values.customerPhoneNumber?.split("-").join(""),
      workshopWebsite: values.workshopWebsite,
      location: {
        zipCode: values.location?.zipCode,
        city: values.location?.city,
        municipality: values.location?.municipality,
        neighborhood: values.location?.neighborhood,
        address: values.location?.address,
        latitude:
          values.location?.latitude === "0" ? "" : values.location?.latitude,
        longitude:
          values.location?.longitude === "0" ? "" : values.location?.longitude,
        WorkshopStateProvince: values.location?.WorkshopStateProvince,
      },
      workshopPhotos: photosOrdered,
      availableForEmergencies: values.availableForEmergencies
        ? typeof values.availableForEmergencies === "string"
          ? values.availableForEmergencies
          : values.availableForEmergencies.toString()
        : "false",
      workingHours: workingHours,
      workshopSocialMedia: values.workshopSocialMedia,
      allowWhatsapp: false,
      carTypes: newCarTypes,
      additionalInfo: values.additionalInfo,
      workshopBrands: values.workshopBrands || [],
      workshopCategoryId: values.workshopCategoryId,
      about: values.about || "",
      WorkshopIdentificator: values.workshopIdentificator,
      WorkshopRFC: values.workshopRFC,
      WorkshopSocialReason: values.workshopSocialReason,
      sucursalName: workshop.sucursalName,
      workshopFranchise: workshop.workshopFranchise,
      mySAPCode: workshop.mySAPCode,
      isMobilClient: workshop.isMobilClient,
      workshopServiceWarranty: values.warranty.enable,
      workshopWarrantyFileUrl: values.warranty.conditions.includes("https")
        ? values.warranty.conditions
        : "",
    };

    blobListDeleted.forEach((res) => {
      containerClient.deleteBlob(res.name, sasToken);
    });

    if (exitModal) nextSetting();

    UpdateWorkshop(toUpdate);
    sessionStorage.removeItem("imagesWorkshop");
  };

  const validateCoords = async (
    latitude: string,
    longitude: string,
    values: any,
    exitModal: boolean = true
  ) => {
    setSaving(true);
    let isMissingFields: boolean = false;
    await form.validateFields().catch(() => {
      isMissingFields = true;
    });

    let continueUpdating: boolean = true;

    if (
      latitude &&
      longitude &&
      latitude !== "" &&
      longitude !== "" &&
      values.location.address !== ""
    ) {
      let coords = latitude + "," + longitude;
      await WorkshopAPI.areCoordinatesAllowed(workshop.id, coords).then(
        (response) => {
          if (response.data) {
            continueUpdating = true;
          } else {
            setSaving(false);
            CkMessage({
              type: "warning",
              text: "Ya existe otro taller en la ubicación seleccionada",
            });

            continueUpdating = false;
          }
        }
      );
    }
    if (continueUpdating) {
      doUpdate(values, exitModal)
        .then(() => {
          if (isMissingFields)
            CkMessage({
              type: "info",
              text: "Recuerda regresar más tarde a completar toda la información de tu taller.",
            });

          setSaving(false);
        })
        .catch((error) => {
          console.info("error", error);
          setSaving(false);
          if (error.toString().endsWith("WHRERROR")) {
            CkMessage({
              type: "warning",
              text: "Horarios no seleccionados en día habilitado.",
            });
          } else if (
            error &&
            error.errorFields[0] &&
            error.errorFields[0].errors[0]
          ) {
            CkMessage({
              type: "warning",
              text: error.errorFields[0].errors[0],
            });
          } else {
            CkMessage({
              type: "warning",
              text: "Verifica todos los campos del formulario.",
            });
          }
        });
    }
  };

  const validateInfo = async (dataForm: any) => {
    let enabled = true;
    const wh = dataForm.workingHours;
    const svList = dataForm.serviceTypes;

    if (dataForm.UseSameTimeEveryDay == true) {
      if (dataForm.mondayToFridayDayHours) {
        if (
          dataForm.everyDayHours == undefined ||
          dataForm.everyDayHours.endingTime == undefined ||
          dataForm.everyDayHours.startingTime == undefined
        ) {
          enabled = false;
          CkMessage({
            type: "warning",
            text: "Ingresa horario de trabajo para todos los dias",
          });
        }
      }
    } else if (dataForm.UseSameTimeMondayToFriday == true) {
      if (
        dataForm.mondayToFridayDayHours.endingTime == undefined ||
        dataForm.mondayToFridayDayHours.startingTime == undefined
      ) {
        enabled = false;
        CkMessage({
          type: "warning",
          text: "Ingresa horario de trabajo de lunes a viernes",
        });
      }
    } else {
      if (wh) {
        let h = "";
        wh.map((res: any) => {
          if (res.openTime.length > 0) {
            h = res.openTime.length;
          }
        });
        if (h.length == 0) {
          enabled = false;
          CkMessage({ type: "warning", text: "Ingresa horario de trabajo" });
        }
      } else {
        enabled = false;
        CkMessage({ type: "warning", text: "Ingresa horario de trabajo" });
      }
    }

    const isPackageEmpty = !packageList.some((res: any) => res.price > 0);
    if (
      hidePackagesAndServices === false &&
      (!packageList || isPackageEmpty) &&
      (!svList || svList.length === 0)
    ) {
      enabled = false;
      CkMessage({
        type: "warning",
        text: "Selecciona al menos un paquete o servicio.",
      });

      setSaving(false);
    } else if (!packageList && !svList) {
      enabled = false;
      CkMessage({
        type: "warning",
        text: "Selecciona al menos un paquete o servicio.",
      });

      setSaving(false);
    }

    if (enabled && workshop) {
      savePackages(workshop.id!);
      saveServices(workshop.id!, svList);
    }
    return enabled;
  };

  const onCheck = async (exitModal: boolean = true) => {
    let dataName: Array<IVal> = [];

    if (workshop === undefined) return;
    setSaving(true);
    form
      .validateFields()
      .then(async () => {
        let values = form.getFieldsValue();
        const available: boolean = await validateInfo(values);

        if (available) {
          let nameValue = values.name;
          let nameUpperCase = nameValue.toUpperCase();
          values.sucursalName = values.sucursalName || ""; // This field was removed kept it to preserve consistency
          values.workshopWebsite = workshop.workshopWebsite || ""; // This field was removed kept it to preserve consistency
          values.workshopSocialMedia = workshop.workshopSocialMedia || []; // This field was removed kept it to preserve consistency
          values.openSince = workshop.openSince || ""; // This field was removed kept it to preserve consistency

          if (nameUpperCase === workshop.name) {
            if (workshop.id) {
              //let id = workshop.id; //parseInt(workshops[workshopSelected].id);
              //let coords = values.location?.latitude + "," + values.location?.longitude;
              validateCoords(
                values.location?.latitude,
                values.location?.longitude,
                values,
                exitModal
              );
            } else {
              setSaving(false);
              CkMessage({
                type: "warning",
                text: "No fue posible identificar el taller solicitado",
              });
            }
          } else {
            // return;
            WorkshopAPI.searchDuplicates(undefined, nameUpperCase, undefined)
              .then((response) => {
                dataName = response.data;

                if (dataName.length > 0) {
                  let filtered = dataName.filter(
                    (res) => res.email === user?.email
                  );
                  if (filtered.length > 0) {
                    if (workshop.id) {
                      validateCoords(
                        values.location?.latitude,
                        values.location?.longitude,
                        values
                      );
                    } else {
                      setSaving(false);
                      CkMessage({
                        type: "warning",
                        text: "No fue posible identificar el taller solicitado",
                      });
                    }
                  } else {
                    setSaving(false);
                    CkMessage({
                      type: "warning",
                      text: "Ya existe un taller con este nombre en otra cuenta",
                    });
                  }
                } else {
                  if (workshop.id) {
                    validateCoords(
                      values.location?.latitude,
                      values.location?.longitude,
                      values
                    );
                  } else {
                    setSaving(false);
                    CkMessage({
                      type: "warning",
                      text: "No fue posible identificar el taller solicitado",
                    });
                  }
                }
              })
              .catch((_) => {
                setSaving(false);
              });
          }
        }
      })
      .catch((errorInfo: any) => {
        setSaving(false);
        if (
          errorInfo &&
          errorInfo.errorFields[0] &&
          errorInfo.errorFields[0].errors[0]
        ) {
          CkMessage({
            type: "warning",
            text: errorInfo.errorFields[0].errors[0],
          });
        } else {
          CkMessage({
            type: "warning",
            text: "Verifica todos los campos del formulario.",
          });
        }
      });
  };

  return (
    <div
      className="cl-color-control"
      style={{
        padding: "15px max(3%, 15px) 0px",
        maxWidth: "1054px",
        width: "100%",
      }}
    >
      {topAffix && (
        <Affix offsetTop={63}>
          <div
            style={{
              width: "100%",
              background: "#F8F8F8",
            }}
          >
            <Row
              gutter={[0, 16]}
              justify="center"
              style={{ alignItems: "center" }}
            >
              <Col
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                className="web"
                style={{
                  textAlign: "end",
                }}
              ></Col>
            </Row>
          </div>
        </Affix>
      )}
      <Layout>
        {workshopForm?.name !== undefined ? (
          <Form
            form={form}
            className="layout-on-web"
            name="workshop_setup"
            initialValues={workshopForm}
            scrollToFirstError={true}
          >
            <ExxonWorkshop
              workshop={workshop}
              setWorkshop={HandlerSetForm}
              canEdit={true}
              form={form}
              blobListDeleted={blobListDeleted}
              setBlobListDeleted={setBlobListDeleted}
              packageList={packageList}
              prevSetting={prevSetting}
              saveData={() => onCheck(false)}
              hidePackagesAndServices={hidePackagesAndServices}
            />
            <Affix
              offsetBottom={0}
              style={{ width: "100%" }}
              className="mobile-block"
            ></Affix>
            <div
              style={{
                display: "flex",
                justifyContent: windowSize.width > 992 ? "flex-end" : "center",
                gap: windowSize.width > 992 ? "159px" : "35px",
                marginTop: windowSize.width > 992 ? 0 : "20px",
              }}
            >
              {prevSetting && (
                <CkButton
                  variant="link"
                  block={false}
                  onClick={() => prevSetting()}
                >
                  Cancelar
                </CkButton>
              )}
              <CkButton
                className="button-style"
                onClick={() => onCheck()}
                loading={loading || saving}
              >
                {nextStepText ? nextStepText : "Guardar datos"}
              </CkButton>
            </div>
          </Form>
        ) : (
          <Spin size="large" />
        )}
      </Layout>
      <ConfirmationMessage
        visible={displayModal}
        title={"¿Desea salir sin guardar sus cambios?"}
        message={""}
        onConfirm={() => setDisplayModal(false)}
        onClose={() => prevSetting()}
        okText={"Seguir editando"}
        cancelText={"Salir"}
        showcloseIcon={false}
      />
    </div>
  );
};
